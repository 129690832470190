import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../use-stores';
import { authProvider } from "../auth/AuthProvider";
import { AuthenticationState } from "react-aad-msal";
import EmbeddedDashboardElement from './EmbeddedDashboardPage';
import { Button, ListGroup } from 'react-bootstrap';
import Header from '../components/header';


const ErrorsPage: React.FC = observer((props) => {
    const { rootStore } = useStores();

    let errors = <div>no errors</div>
    if (rootStore.errorStore.errors.length > 0)
    {
        let errorItems: any[] = []
        rootStore.errorStore.errors.forEach((value: string, index:number) => {
            errorItems.push(<ListGroup.Item>{value}</ListGroup.Item>)
        });
        errors = <>
                    <Button  onClick={() => rootStore.errorStore.clearErrors()} variant="outline-dark">Clear Errors</Button>
                    <ListGroup>{errorItems}</ListGroup>
                 </>
    }


    return (
        <>
            <Header/>
            <h1>Errors</h1>
            {errors}
        </>
   
  )
});

export default ErrorsPage;