import { DashboardStore } from "./DashboardStore";
import { UserStore } from "./UserStore";
import { ErrorStore } from "./ErrorStore";

export class RootStore {
    errorStore: ErrorStore
    userStore: UserStore
    dashboardStore: DashboardStore

    constructor() {
        this.errorStore = new ErrorStore(this)
        this.userStore = new UserStore(this)
        this.dashboardStore = new DashboardStore(this)
    }
}