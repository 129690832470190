
import { action, makeObservable, computed, observable } from "mobx"
import { authProvider } from "../auth/AuthProvider";
import { AuthenticationState } from "react-aad-msal";
import { RootStore } from "./RootStore";
import { IDashboard, LoadState } from "./DashboardStore";
import Axios from "axios";
import { appConfig } from "../config/app-config";



export interface IBrand {
    logo: string;
    header: string;
    background: string;
    dropdown: string;
    navLinks: string;
    errorMessage: string;
    table: string;
}

export interface IUserInfoResult {
    user: string,
    dashboards: IDashboard[],
    errors: string[],
    brand: string
}

export class UserStore {
    rootStore: RootStore
    whiteLabels: {[brand: string] : IBrand} = {}
    dashboards: IDashboard[] = []
    userInfoLoadState: LoadState = LoadState.NOT_LOADED
    brandName: string = ""

    constructor(rootStore: RootStore) {
        makeObservable(this, {
            name: computed,
            dashboards: observable,
            brandName: observable,
            userInfoLoadState: observable,
            roles: computed,
            rolesString: computed,
            accountObject: computed,
            authStatus: computed,
            login: action.bound,
            logout: action.bound,
            getUserInfo: action.bound
        })
        this.rootStore = rootStore

        this.whiteLabels.equilaw = {
            logo: " d-inline-block align-top equilaw-logo",
            header: "equilaw-header",
            background: "equilaw-background",
            dropdown: "equilaw-dropdown",
            navLinks: "equilaw-nav-link",
            errorMessage: "equilaw-error-message",
            table: "equilaw-table"
        }
    }

    get brand(): IBrand {
        if (appConfig.brand != undefined && appConfig.brand in this.whiteLabels)
        {
            console.log(`Brand is : ${appConfig.brand}`)
            return this.whiteLabels[appConfig.brand] as IBrand
        }

        if (this.brandName in this.whiteLabels)
            return this.whiteLabels[this.brandName] as IBrand

        return this.whiteLabels["rg"] 
    }

    get name(): string {
        try
        {
            if (authProvider.authenticationState === AuthenticationState.Unauthenticated) 
                return "unknown"
            return authProvider.getAccount().name
        }
        catch (error)
        {
            console.error(`Failed to get name : ${error}`)
            return "unknown"
        }
    }

    get roles(): {} {
        try
        {
            if (authProvider.authenticationState === AuthenticationState.Unauthenticated) 
                return {"role": "unknown"}

            let roles = authProvider.getAccount().idTokenClaims.roles
            console.log(`account: ${JSON.stringify(authProvider.getAccount())}`)
            if (roles == undefined)
                throw new Error("undefined roles returned from idToken")

            return roles
        }
        catch (error: unknown)
        {
            console.error(`Failed to get roles`)
        }
        return {"role": "unknown"}
    }

    get rolesString() : string {
        let roles = ""
        if (this.roles == undefined)
            return roles

        Object.values(this.roles).forEach((value: unknown, index:number, arr: unknown[]) => {
          roles += value as string
          roles += ", "
        });
        if (roles.length > 2)
            roles = roles.substring(0, roles.length - 2)
        return roles
    }

    get isAdmin() : boolean {
        let admin = false
        try
        {
            Object.values(this.roles).forEach((value: unknown, index:number, arr: unknown[]) => {
                if (value as string == "User.Admin")
                    admin = true
            });
        }
        catch (error)
        {
            console.error(`Failed to check isadmin`)
        }
        return admin
    }

    get email(): string {
        try
        {
            if (authProvider.authenticationState === AuthenticationState.Unauthenticated) 
                return ""

            return authProvider.getAccount().userName
        }
        catch (error)
        {
            console.error(`Failed to get email : ${error}`)
            return "unknown"
        }
    }

    get accountObject() : {} {
        try
        {
            if (authProvider.authenticationState === AuthenticationState.Unauthenticated) 
                return ""

            return authProvider.getAccount()
        }
        catch (error)
        {
            console.error(`Failed to get account object: ${error}`)
            return {"account": "unknown"}
        }
    }

    get authStatus() : string {
        try
        {
            return authProvider.authenticationState
        }
        catch (error)
        {
            console.error(`Failed to get auth status ${error}`)
            return "error"
        }
        
    }

    login() : void {
        try
        {
            console.log("logging in...")
            authProvider.login()
            console.log("logging in complete")
        }
        catch (error)
        {
            console.error(`Failed to login : ${error}`)
        }

    }


    logout() : void {
        console.log("logging out...")
        authProvider.logout()
    }

    async getUserInfo()  {
        if (authProvider.authenticationState === AuthenticationState.Unauthenticated) {
            this.rootStore.errorStore.add(`Unable to get dashboards as user is not logged in`)
            console.error(`getDashboards : not logged in`)
            return
        }

        if (this.userInfoLoadState == LoadState.LOADING)
        {
            console.log(`Usre Info load already in progress`)
            return
        }

        this.userInfoLoadState = LoadState.LOADING
        console.log("getUserInfo : get access token")
        let accessTokenResponse = await authProvider.getAccessToken();
        console.log(`getUserInfo : get dashboards ${appConfig.apiRoute}`)
        await Axios
            .get<IUserInfoResult>(`${appConfig.apiRoute}/userinfo`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + accessTokenResponse.accessToken
                }
            })
            .then((response) => {
                console.log(`Received dashboards : ${JSON.stringify(response.data)}`)
                //localStorage.setItem('dashboards', response.data.dashboards.map(dash => dash.id).toString());

                this.dashboards = response.data.dashboards;
                this.brandName = response.data.brand
                this.userInfoLoadState = LoadState.LOADED
            })
            .catch(e => {
                let message = ""
                let response = ""
                if (e !== undefined)
                {
                    message = e.message
                    if (e.response !== undefined)
                        response = e.response.data
                }
                this.rootStore.errorStore.add(`Exception loading user info : ${e} ${message} : ${JSON.stringify(response)}`)
            })
    } 
}
