import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../use-stores';
import { Link } from 'react-router-dom';


const ErrorComponent: React.FC = observer((props) => {
    const { rootStore } = useStores();

    let errors = <></>
    if (rootStore.errorStore.errors.length > 0)
    {
        errors = <div className={rootStore.userStore.brand.errorMessage}><Link to="/errors">click here to see errors</Link></div>
    }


    return (<>{errors}</>)
});

export default ErrorComponent;