import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../use-stores';
import { authProvider } from "../auth/AuthProvider";
import { AuthenticationState } from "react-aad-msal";
import { LoadState } from '../stores/DashboardStore';
import Header from '../components/header';
import { Navigate } from 'react-router';
import ErrorComponent from '../components/ErrorComponent';


const HomePage: React.FC = observer((props) => {
  const { rootStore } = useStores();

  if (authProvider.authenticationState === AuthenticationState.Authenticated && rootStore.userStore.userInfoLoadState === LoadState.NOT_LOADED)
  {
      console.log(`Loading dashboartds : ${rootStore.userStore.userInfoLoadState} : ${authProvider.authenticationState}`)
      rootStore.userStore.getUserInfo()
  }


  if (authProvider.authenticationState !== AuthenticationState.Authenticated)
      return (<Navigate replace to="/login"/>)

  return (
    <>
      <Header/>
      <ErrorComponent/>
      {/* <div className="background-image"></div> */}
      <div className={rootStore.userStore.brand.background}></div>
    </>
  )
});

export default HomePage;