
import { makeObservable, observable } from "mobx"
import { RootStore } from "./RootStore";

export class ErrorStore {
    errors: string[] = []
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        makeObservable(this, {
            errors: observable,
        })
        this.rootStore = rootStore
    }

    add(error: string) : void {
        console.error(`adding error : ${error}`)
        this.errors.push(error)
    }

    clearErrors() : void {
        this.errors = []
    }
}
