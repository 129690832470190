import React from 'react';
import Navbar from "react-bootstrap/cjs/Navbar";
import { observer } from 'mobx-react';
import { useStores } from '../use-stores';
import { NavDropdown, Nav, Button, Container, NavItem, Dropdown } from 'react-bootstrap';
import { LoadState, IDashboard } from '../stores/DashboardStore';
import { AuthenticationState } from "react-aad-msal";
import { Link, NavLink } from 'react-router-dom';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { appConfig } from '../config/app-config';
import spinner from '../images/spinner.svg'

const Loading: React.FC = observer((props) => {
    return (
        <div className='loading-container'>
            <img src={spinner} alt="A blue circle that is spinning" className='loading-spinner'/>
            <h2 className='loading-text'>Loading...</h2>
        </div>
    )
})

export default Loading