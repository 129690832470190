import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../use-stores';
import { authProvider } from "../auth/AuthProvider";
import { AuthenticationState } from "react-aad-msal";
import { Table } from 'react-bootstrap';
import { Navigate } from 'react-router';
import Header from '../components/header';
import ErrorComponent from '../components/ErrorComponent';


const UserProfilePage: React.FC = observer((props) => {
  const { rootStore } = useStores();

  if (authProvider.authenticationState !== AuthenticationState.Authenticated)
      return (<Navigate replace to="/login"/>)

  let message = <div>Please login</div>
  if (authProvider.authenticationState === AuthenticationState.Authenticated)
  {
    let roles = ""

    Object.values(rootStore.userStore.roles).forEach((value: unknown, index:number, arr: unknown[]) => {
      roles += value as string
      roles += ", "
    });

    if (roles.length > 2)
      roles = roles.substring(0, roles.length - 2)

    message = <Table responsive="col-12 col-sm-4" className={rootStore.userStore.brand.table}>
            <tbody>
            <tr>
                <td>Name</td>
                <td>{rootStore.userStore.name}</td>
            </tr>
            <tr>
                <td>Roles</td>            
                <td>{roles}</td>
            </tr>
            <tr>
                <td>email</td>
                <td>{rootStore.userStore.email}</td>
            </tr>
            </tbody>
        </Table>
    }

    return (<>
                <Header/>
                <ErrorComponent/>
                <div className={rootStore.userStore.brand.background}>
                  <br/>
                {message}
                </div>
                </>)
});

export default UserProfilePage;