import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../use-stores';
import { authProvider } from "../auth/AuthProvider";
import { AuthenticationState } from "react-aad-msal";
import { Button, Form } from 'react-bootstrap';
import { Navigate } from 'react-router';


const LoginPage: React.FC = observer((props) => {
  const { rootStore } = useStores();

  if (authProvider.authenticationState === AuthenticationState.Authenticated)
      return (<Navigate replace to="/"/>)

  return (
    <div className="border d-flex align-items-center justify-content-center max-div background-image">
        <Form className="login-form rounded p-4 p-sm-3 w-30"> 
            <br/>
            <div className="text-center">
            <Form.Label ><h1>Welcome</h1></Form.Label>
            <p>You must be logged in to use this app.<br/>
            Please click the button below to login.</p>
            
                <Button onClick={rootStore.userStore.login} className='justify-items-center'>Login</Button>
            </div>
            <br/>
        </Form>
    </div>
)
});

export default LoginPage;