import React from 'react';
import Navbar from "react-bootstrap/cjs/Navbar";
import { observer } from 'mobx-react';
import { useStores } from '../use-stores';
import { NavDropdown, Nav, Button, Container, NavItem, Dropdown } from 'react-bootstrap';
import { LoadState, IDashboard } from '../stores/DashboardStore';
import { AuthenticationState } from "react-aad-msal";
import { Link, NavLink } from 'react-router-dom';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { appConfig } from '../config/app-config';


const Header: React.FC = observer((props) => {
    const { rootStore } = useStores();

    const links: any[] = [];
    if (rootStore.userStore.userInfoLoadState === LoadState.NOT_LOADED)
    {
        links.push(<DropdownItem key="load_dashboards" onClick={rootStore.userStore.getUserInfo}>Load Dashboards</DropdownItem>)
    }
    else if (rootStore.userStore.userInfoLoadState === LoadState.LOADING)
    {
        links.push(<DropdownItem key="loading_dashboards">Loading Dashboards</DropdownItem>)
    }
    else if (rootStore.userStore.userInfoLoadState === LoadState.LOADED)
    {
        rootStore.userStore.dashboards.forEach((value: IDashboard, index:number) => {
            //links.push(<NavDropdown.Item key={`${value.id}`}><Nav.Link as={NavLink} to={`/dashboard/${value.id}`}>{value.name}</Nav.Link></NavDropdown.Item>)
            links.push(<NavDropdown.Item key={`${value.id}`} as={NavLink} to={`/dashboard/${value.id}`} className={rootStore.userStore.brand.dropdown}>{value.name}</NavDropdown.Item>)
            //links.push(<NavDropdown.Item key={`${value.id}`} href={`/dashboard/${value.id}`}>{value.name}</NavDropdown.Item>)
            //links.push(<NavDropdown.Item key={`${value.id}`}><Link to={`/dashboard/${value.id}`}>{value.name}</Link></NavDropdown.Item>)
            //links.push(<NavLink to={`/dashboard/${value.id}`} key={`${value.id}`}>{value.name}</NavLink>)
        });
    }

    let login = <Button variant="outline-light" onClick={rootStore.userStore.login}>Login</Button>
    let dashboards = <div></div>
    let admin = <></>
    if (rootStore.userStore.authStatus === AuthenticationState.Authenticated)
    {
        login = <NavDropdown title={rootStore.userStore.name} id="navbarDropdown" className={rootStore.userStore.brand.navLinks}>
                    <NavDropdown.Item key={"profileoption"} as={NavLink} to={`/profile`} >Profile</NavDropdown.Item>
                    <NavDropdown.Item onClick={rootStore.userStore.logout}>Logout</NavDropdown.Item>
                </NavDropdown>
        
        dashboards = <NavDropdown title="Dashboards" id="navbarDropdown" className={rootStore.userStore.brand.navLinks}>
                        {links}
                     </NavDropdown>

        if (rootStore.userStore.isAdmin)
            admin = <Nav.Item  key={"adminmenun"} as={NavLink} to={`/admin`} className={rootStore.userStore.brand.navLinks}>
                        Admin
                    </Nav.Item>
    }


    return (
        <>
            {/* <Navbar collapseOnSelect bg="dark" expand="lg" variant="dark"> */}
            <Navbar collapseOnSelect expand="lg" className={rootStore.userStore.brand.header}>
                <Nav className="container-fluid">
                    <Nav.Item>
                        <Navbar.Brand as={NavLink} to="/">
                            <img
                                className={rootStore.userStore.brand.logo}
                                height="30"
                                width="150"
                                alt="analytics"
                            />
                            <img
                                className="retirement-logo"
                                height="30"
                                width="150"
                                alt="analytics"
                            />
                        </Navbar.Brand>
                    </Nav.Item>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                                {admin}
                                {dashboards}
                            <Nav.Item className="ml-auto">
                                {login}
                            </Nav.Item>
                        </Navbar.Collapse>
                    </Nav>
            </Navbar>
      </>
    );
})

export default Header;