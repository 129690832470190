import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import {Configuration} from "msal";
// import { Configuration,  PublicClientApplication } from "@azure/msal-browser";
import {appConfig} from "../config/app-config"


const config : Configuration = {
    auth: {
        clientId: appConfig.authConfig.clientId,
        authority: appConfig.authConfig.authority,
        validateAuthority: true,
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation:  "localStorage",
        storeAuthStateInCookie: true
    }
};

// Authentication Parameters
const authenticationParameters = {
    // scopes: [appConfig.authConfig.serverClientId + '/user_impersonation'] 
    scopes: [`${appConfig.authConfig.clientId}/.default`] 
    // scopes: [`https://${appConfig.authConfig.tenant}.onmicrosoft.com/${appConfig.authConfig.clientId}/.default`],
    // tenantid: appConfig.authConfig.tenant
    // scopes: [`${appConfig.authConfig.clientId}`] 
}

// Options
const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin + '/auth.html'
}

console.info(`Refresh URI : ${window.location.origin} : ${JSON.stringify(authenticationParameters)}`)


export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)

console.info("MSAL initialised")