import internal from "stream";

export interface IAppConfig{
    dev : IEnvironmentConfig
    prd : IEnvironmentConfig
}

export interface IEnvironmentConfig{
    authConfig : IAuthConfig
    apiRoute: string;
    brand: string | undefined;
    title: string

}

export interface IAuthConfig{
    clientId: string;
    tenant: string;
    authority: string;
}

// https://stackoverflow.com/questions/51313330/how-to-use-environment-variables-in-react-app-hosted-in-azure

const getAppConfig = () : IEnvironmentConfig => {

    console.log("process.env.REACT_APP_ENVIRONMENT: ", process.env.REACT_APP_ENVIRONMENT)

    let default_config: IEnvironmentConfig = {
        authConfig: {
            clientId: 'b58edcec-afe3-4e29-a242-72ed7832972d', 
            tenant: '88ba8b20-5a61-4374-b4fd-37039cad0d53', // Equilaw
            authority: 'https://login.microsoftonline.com/88ba8b20-5a61-4374-b4fd-37039cad0d53'
        },
        apiRoute: "", 
        brand: "equilaw",
        title: "Equilaw Analytics"
    }


    if (process.env.REACT_APP_ENVIRONMENT === "local")
    {
        default_config.apiRoute = "http://localhost:5005" 
    }
    else if (process.env.REACT_APP_ENVIRONMENT === "equilaw-dev")
    {
        default_config.apiRoute = "https://equilawclientportalserver-dev.azurewebsites.net/" 
    }
    else if (process.env.REACT_APP_ENVIRONMENT === "equilaw-prod")
    {
        default_config.apiRoute = "https://equilawclientportalserver.azurewebsites.net/" 
    }

    console.log(`config : ${JSON.stringify(default_config)}`)

    return default_config
}

export const appConfig = getAppConfig();

