import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../use-stores';
import { authProvider } from "../auth/AuthProvider";
import { AuthenticationState } from "react-aad-msal";
import EmbeddedDashboardElement from './EmbeddedDashboardPage';
import { Button, ListGroup, Table } from 'react-bootstrap';
import Header from '../components/header';
import { useNavigate } from 'react-router';
import ErrorComponent from '../components/ErrorComponent';
import { appConfig } from '../config/app-config';


const AdminPage: React.FC = observer((props) => {
    const { rootStore } = useStores();

    let navigate = useNavigate(); 


    let errors = <div>no errors</div>
    if (rootStore.errorStore.errors.length > 0)
    {
        let errorItems: any[] = []
        rootStore.errorStore.errors.forEach((value: string, index:number) => {
            errorItems.push(<ListGroup.Item>{value}</ListGroup.Item>)
        });
        errors = <ListGroup>{errorItems}</ListGroup>
    }


    return (
        <>
            <Header/>
            <ErrorComponent/>
            <Table responsive="col-12 col-sm-6">
                <tbody>
                    <tr>
                        <td>Authentication State</td>
                        <td>{rootStore.userStore.authStatus}</td>
                    </tr>
                     <tr>
                        <td>email</td>
                        <td>{rootStore.userStore.email}</td>
                    </tr>
                    <tr>
                        <td>roles</td>
                        <td>{rootStore.userStore.rolesString}</td>
                    </tr> 
                    <tr>
                        <td>Environment</td>
                        <td>{process.env.REACT_APP_ENVIRONMENT}</td>
                    </tr>
                     <tr>
                        <td>Account Object</td>
                        <td><pre>{JSON.stringify(rootStore.userStore.accountObject, undefined, 2)}</pre></td>
                    </tr>
                    <tr>
                        <td> <Button  onClick={rootStore.userStore.getUserInfo} variant="outline-dark">Get User Info</Button></td>            
                        <td><pre>{JSON.stringify(rootStore.userStore.dashboards, undefined, 2)}</pre></td>
                    </tr>
                    <tr>
                        <td>User Brand Name</td>            
                        <td>{rootStore.userStore.brandName}</td>
                    </tr>
                    <tr>
                        <td>User brand classes</td>            
                        <td><pre>{JSON.stringify(rootStore.userStore.brand, undefined, 2)}</pre></td>
                    </tr>
                    <tr>
                        <td>Selected Dashboard</td>
                        <td><pre>{JSON.stringify(rootStore.dashboardStore.selectedDashboard, undefined, 2)}</pre></td>
                    </tr>
                    <tr>
                        <td><Button  onClick={rootStore.dashboardStore.getFirstDashboard} variant="outline-dark">Get Embed</Button></td>
                        <td><pre>{JSON.stringify(rootStore.dashboardStore.embeddedDashboard, undefined, 2)}</pre></td>
                    </tr> 
                    <tr>
                        <td>App Config</td>
                        <td><pre>{JSON.stringify(appConfig, undefined, 2)}</pre></td>
                    </tr>
                    <tr>
                        <td>Environment</td>
                        <td><pre>{JSON.stringify(process.env, undefined, 2)}</pre></td>
                    </tr>
                </tbody>
            </Table>
        </>
   
  )
});

export default AdminPage;