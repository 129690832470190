import * as React from 'react';
import './App.css';
import TestComponent from './pages/AdminPage';

import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Header from './components/header';
import EmbeddedDashboardPage from './pages/EmbeddedDashboardPage';
import HomePage from './pages/HomePage';
import AdminPage from './pages/AdminPage';
import UserProfilePage from './pages/UserProfilePage';
import LoginPage from './pages/LoginPage';
// import { authProvider } from './auth/AuthProvider';
// import { AuthenticationState } from 'react-aad-msal';
import ErrorsPage from './pages/ErrorsPage';
import { useEffect } from 'react';
import { appConfig } from "./config/app-config";


const App: React.FC = () => {
  useEffect(() => {
    document.title = appConfig.title;
  });

  const routes = (
    <Routes>
        <Route path="/" element={<HomePage />}/>
        <Route path="/admin" element={<AdminPage/>} />
        <Route path="/404" element={<div>Choose the correct path</div>} />
        <Route path="*" element={<div>Unknown path</div>} />     
        <Route path="/dashboard/:dashboardId" element={<EmbeddedDashboardPage />} />
        <Route path="/dashboard" element={<EmbeddedDashboardPage />} />
        <Route path="/profile" element={<UserProfilePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/errors" element={<ErrorsPage />} />
        {/* <Route path="*" element={<Navigate replace to="/404" />} />         */}
    </Routes>
  );

  return  (
            <BrowserRouter>
            {routes}
            </BrowserRouter>
          );
}

export default App;

