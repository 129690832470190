import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../use-stores';
import { PowerBIEmbed } from "powerbi-client-react";
import { models, Report, Embed } from 'powerbi-client';
import { Navigate, useParams } from 'react-router';
import { LoadState } from '../stores/DashboardStore';
import { authProvider } from '../auth/AuthProvider';
import { AuthenticationState } from 'react-aad-msal';
import Header from '../components/header';
import ErrorComponent from '../components/ErrorComponent';
import Loading from '../components/loading'
import { appConfig } from "../config/app-config";

const EmbeddedDashboardPage: React.FC = observer((props) => {
    const { rootStore } = useStores();
    const [report, setReport] = useState<Report>();

    if (authProvider.authenticationState !== AuthenticationState.Authenticated)
        return (<Navigate replace to="/login"/>)

    // const query = new URLSearchParams(useLocation().search);
    // console.log(`query:${JSON.stringify(query)}`)
    // console.log(`all:${JSON.stringify(query.get("dashboardId"))}`)
    let { dashboardId } = useParams();
    console.log(`selected id:${dashboardId}`)
    if (dashboardId !== undefined)
    {
        let result = rootStore.dashboardStore.getDashboard(dashboardId)
        if (!result)
            return (<Navigate replace to="/error"/>)
    }


    let embed = <div>dashboard is null</div>
    console.log(`embeddedDashboard : ${rootStore.dashboardStore.embeddedDashboard} : ${rootStore.dashboardStore.selectedDashboard} : ${rootStore.dashboardStore.embeddedDashboardState}`)
    if (rootStore.dashboardStore.embeddedDashboardState == LoadState.FAILED)
    {
        embed = <>      
                    Failed to load dashboard with error : {`${rootStore.dashboardStore.getDashboardError}`}
                </>
    }
    else if (rootStore.dashboardStore.embeddedDashboard != null && rootStore.dashboardStore.embeddedDashboard !== undefined)
    {   
        console.log("embeddedDashboard : generate embed")
        let dashboard = rootStore.dashboardStore.embeddedDashboard 
        let filter_visible = appConfig.brand == "ukvc"
        embed = <PowerBIEmbed
                    embedConfig={{
                        type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                        id: dashboard.reportId,
                        embedUrl: dashboard.embedUrl,
                        accessToken: dashboard.embedToken,
                        tokenType: models.TokenType.Embed,
                        settings: {
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: filter_visible
                                },
                                pageNavigation: {
                                    visible: false,
                                    position: models.PageNavigationPosition.Left,
                                }
                            },
                            background: models.BackgroundType.Transparent

                            
                            // layoutType: models.LayoutType.Custom,
                            // customLayout: {
                            //     pageSize: {
                            //         type: models.PageSizeType.Custom,
                            //     },
                            //     displayOption: models.DisplayOption.ActualSize,
                                // pagesLayout: {
                                //     "ReportSection1" : {
                                //         defaultLayout: {
                                //             displayState: {
                                //                 mode: models.VisualContainerDisplayMode.Hidden
                                //             }
                                //         },
                                //         visualsLayout: {
                                //             "VisualContainer1": {
                                //                 x: 1,
                                //                 y: 1,
                                //                 z: 1,
                                //                 width: 400,
                                //                 height: 300,
                                //                 displayState: {
                                //                     mode: models.VisualContainerDisplayMode.Visible
                                //                 }
                                //             },
                                //             "VisualContainer2": {
                                //                 displayState: {
                                //                     mode: models.VisualContainerDisplayMode.Visible
                                //                 }
                                //             },
                                //         }
                                //     }
                                // }
                            // }
                        }
                    }}

                    // Define event handlers
                    eventHandlers={
                        new Map([
                            ['loaded', function () { console.log('Report loaded'); }],
                            ['rendered', function () { console.log('Report rendered'); }],
                            ['error', function (event) { console.log(event?.detail); }]
                        ])}

                    // Add CSS classes to the div element
                    cssClassName={"report-style-class2"}

                    // Get reference to the embedded object
                    // getEmbeddedComponent={(embeddedReport) => {
                    //     this.setState({ report: embeddedReport as Report })
                    // }}
                    getEmbeddedComponent = { (embedObject:Embed) => {
                        console.log(`Embedded object of type "${ embedObject.embedtype }" received`);
                        setReport(embedObject as Report);
                    } }
                />
    
    }
    else if (rootStore.dashboardStore.selectedDashboard != null)
    {
        //embed = <div>Loading dashboard...</div>
        embed = <Loading />
    }

    return (
        <>
            <Header/>
            <ErrorComponent/>
            <div className={rootStore.userStore.brand.background}>
                <br/>
                {embed}
            </div>
        </>
     )
});

export default EmbeddedDashboardPage;