import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AzureAD } from "react-aad-msal";
import { authProvider } from "./auth/AuthProvider";

ReactDOM.render(
  <AzureAD provider={authProvider} forceLogin={false}>
  {
    (
      resp: {
        login: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined,
        logout: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined,
        authenticationState: any,
        error: any,
        accountInfo: { account: { name: React.ReactNode; }; }
      }
    ) => {
      return (
          <App />
      );
    }
  }
</AzureAD>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
